import React from "react";
import { connect } from "react-redux";
import { Table } from "../../../components";
import { Summary, Item } from "./components";

const ShiftBatches = ({ data }) => {
  return (
    <>
      <div className="dashboard m-t-0 m-b-0">
        <Table
          switch_warehouse={false}
          table_top_space={20}
          renderItems={() =>
            data?.grn_lines.map((line, index) => (
              <Item
                warehouse_id={data?.warehouse?.id}
                form={String(line?.id)}
                status={data.status}
                is_dispatched={data.is_dispatched}
                key={line?.id}
                initialValues={{
                  product_id: line?.product?.id,
                  counted_quantity: line?.counted_quantity,
                  dispatched_quantity: line?.dispatched_quantity,
                }}
                data={line}
              />
            ))
          }
        />
      </div>
      <Summary />
    </>
  );
};

export default connect(({ grn: { data } }) => ({
  data,
}))(ShiftBatches);
