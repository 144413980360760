import React from "react";
import { connect } from "react-redux";
import { setFilter } from "actions/user_errors_stats";
import { gdn_all_user_error_options } from "utils/gdn_user_error_options";
import { grn_error_options } from "utils/grn_user_error_options";
import Select from "./components/Select";
import DatePicker from "./components/DatePicker";
import { date_options, getHistogramDates } from "utils/histogram_ranges";
import { ReactComponent as InfoIcon } from "icons/info.svg";

const interval_options = [
  { label: "Godzinny", value: "hour" },
  { label: "Dzienny", value: "day" },
  { label: "Tygodniowy", value: "week" },
  { label: "Miesięczny", value: "month" },
  { label: "Roczny", value: "year" },
];

const Filters = ({ status, user_options, filters, setFilter }) => {
  const is_updating = status === "updating";

  return (
    <div className="dashboard">
      <header className="dashboard__header dashboard__header--edit">
        <div className="icon__container">
          <InfoIcon />
        </div>
        <h2 className="dashboard__title heading">Filtry</h2>
      </header>
      <div className="chart-panel">
        <div className="chart-panel__body">
          <div className="chart-panel__form">
            <Select
              is_multi={true}
              label="Pracownik"
              is_loading={is_updating}
              onChange={(values) =>
                setFilter({
                  histogram_user_names:
                    values?.length > 0 ? values.map(({ value }) => value) : [],
                })
              }
              options={user_options}
              value={user_options.filter(({ value }) =>
                filters.histogram_user_names.includes(value)
              )}
            />
            <Select
              is_multi={true}
              label="Rodzaj"
              is_loading={is_updating}
              onChange={(values) =>
                setFilter({
                  histogram_kinds:
                    values?.length > 0 ? values.map(({ value }) => value) : [],
                })
              }
              options={[
                {
                  label: "Błedy podczas kompletacji i pakowania",
                  options: gdn_all_user_error_options,
                },
                {
                  label: "Błedy podczas relokacji",
                  options: grn_error_options,
                },
              ]}
              value={[
                ...gdn_all_user_error_options,
                ...grn_error_options,
              ].filter(({ value }) => filters.histogram_kinds.includes(value))}
            />
            <Select
              label="Typ"
              is_loading={is_updating}
              onChange={({ value: histogram_interval }) =>
                setFilter({ histogram_interval })
              }
              options={interval_options}
              value={interval_options.find(
                ({ value }) => value === filters.histogram_interval
              )}
            />
            <Select
              label="Zakres"
              disabled={is_updating}
              onChange={({ value }) =>
                setFilter({
                  histogram_date_option: value,
                  ...getHistogramDates(value),
                })
              }
              options={date_options}
              value={date_options.find(
                ({ value }) => value === filters.histogram_date_option
              )}
            />
            <DatePicker
              name="created_at"
              label="Data utworzenia"
              onChange={(value) =>
                setFilter({
                  histogram_range_from: value?.[0],
                  histogram_range_to: value?.[1],
                })
              }
              value={[filters.histogram_range_from, filters.histogram_range_to]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const getUserOptions = (users) => {
  const user_names = [...new Set(users.map(({ name }) => name))];

  const user_options = user_names?.map((name) => ({
    label: name,
    value: name,
  }));

  return user_options;
};

export default connect(
  ({
    user_errors_stats: {
      data: { gdn_errors, grn_errors },
      status,
      filters,
    },
  }) => ({
    user_options: getUserOptions([
      ...(gdn_errors?.users || []),
      ...(grn_errors?.users || []),
    ]),
    filters,
    status,
  }),
  (dispatch) => ({
    setFilter: (filter) => dispatch(setFilter(filter)),
  })
)(Filters);
