import { toastr } from "react-redux-toastr";
import { client_v2 } from "utils/api";

export const setGrnLinesFilter = (name, value) => (dispatch) => {
  dispatch({ type: "SET_GRN_LINES_FILTER", name, value });
};

export const setGrnLinesSort = (data) => (dispatch) => {
  dispatch({ type: "SET_GRN_LINES_SORT", data });
  dispatch({ type: "SORT_GRN_LINES" });
};

export const filterGrnLines = () => (dispatch) => {
  dispatch({ type: "FILTER_GRN_LINES" });
};

export const getGrn = (id) => async (dispatch) => {
  dispatch({ type: "GRN_LOADING" });
  try {
    const [{ data: warehouses }, { data }] = await Promise.all([
      client_v2.get(
        "/warehouses?context=list&sort[column]=id&sort[order]=asc&per_page=100"
      ),
      client_v2.get(`/grns/${id}?context=ui_show`),
    ]);

    dispatch({
      type: "GRN_SUCCESS",
      data: {
        ...data,
        warehouses,
      },
    });
  } catch (error) {
    dispatch({ type: "GRN_FAILURE" });
    toastr.error("Błąd", "Wystąpił błąd podczas pobierania");
  }
};

export const updateNote = (id, note) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      await client_v2.put(`/grns/${id}`, {
        note,
      });
      dispatch({
        type: "GRN_UPDATE_NOTE",
        note,
      });
      toastr.success("Sukces", "Notatka została zaktualizowana");
      resolve();
    } catch (error) {
      reject(error);

      toastr.error("Błąd", "Wystąpił błąd podczas dodawania notatki");
    }
  });

export const updateGrnLine = (id, values) => async (dispatch) => {
  try {
    await client_v2.put(`/grn_lines/${id}`, values);
    const {
      data: { grn, ...grn_line },
    } = await client_v2.get(`/grn_lines/${id}?context=ui_grn_show`);
    dispatch({
      type: "GRN_LINE_SUCCESS",
      grn_line,
      grn_status: grn?.status,
      grn_progress: grn?.progress,
    });
    toastr.success("Sukces", "Zapisano");
  } catch (error) {
    toastr.error("Błąd", "Wystąpił błąd podczas edycji");
  }
};

export const setGrnComplete = (id, values) => async (dispatch) => {
  try {
    await client_v2.post(`/grns/${id}/complete`, values);
    const { data } = await client_v2.get(`/grns/${id}`);

    dispatch({
      type: "GRN_UPDATE_SUCCESS",
      data,
    });
    toastr.success("Sukces", "Potwierdzono dostawę");
  } catch (error) {
    toastr.error("Błąd", "Wystąpił błąd");
  }
};

export const reopenGrn = (id) => async (dispatch) => {
  try {
    await client_v2.post(`/grns/${id}/reopen`);
    const { data } = await client_v2.get(`/grns/${id}`);
    dispatch({
      type: "GRN_UPDATE_SUCCESS",
      data,
    });
    toastr.success("Sukces", "Cofnięto potwierdzenie dostawy");
  } catch (error) {
    toastr.error("Błąd", "Wystąpił błąd");
  }
};

export const refreshOrder = (id) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      dispatch({ type: "GRN_LOADING" });
      await client_v2.post(`/grns/${id}/reimport`);
      dispatch(getGrn(id));
      resolve();
    } catch (error) {
      console.log(error);
      reject();
    }
  });

export const setGrnDispatched =
  (id, { dispatch_error }) =>
  async (dispatch) => {
    try {
      await client_v2.post(`/grns/${id}/mark_as_dispatched`, {
        dispatch_error,
      });
      const { data } = await client_v2.get(`/grns/${id}`);

      dispatch({
        type: "GRN_UPDATE_SUCCESS",
        data,
      });
      toastr.success("Sukces", "Wydano pomyślnie");
    } catch (error) {
      toastr.error("Błąd", "Wystąpił błąd");
    }
  };

export const setGrnUnDispatched = (id) => async (dispatch) => {
  try {
    await client_v2.post(`/grns/${id}/unmark_as_dispatched`);
    const { data } = await client_v2.get(`/grns/${id}`);

    dispatch({
      type: "GRN_UPDATE_SUCCESS",
      data,
    });

    toastr.success("Sukces", "Cofnięto wydanie");
  } catch (error) {
    toastr.error("Błąd", "Wystąpił błąd");
  }
};

export const createMM = (id) => async (dispatch) => {
  try {
    await client_v2.post(
      `/grns/${id}/create_shift_from_main_to_shipping_warehouse`
    );
    const { data } = await client_v2.get(`/grns/${id}`);

    dispatch({
      type: "GRN_UPDATE_SUCCESS",
      data,
    });

    toastr.success("Sukces", "Wysłano prośbę o wygenerowanie MM");
  } catch (error) {
    toastr.error("Błąd", "Wystąpił błąd");
  }
};

export const finishGrn = (id) => async (dispatch) => {
  try {
    await client_v2.put(`/grns/${id}`, {
      status: "finished",
    });
    const { data } = await client_v2.get(`/grns/${id}`);

    dispatch({
      type: "GRN_UPDATE_SUCCESS",
      data,
    });

    toastr.success("Sukces", "Zakończono pomyślnie");
  } catch (error) {
    toastr.error("Błąd", "Wystąpił błąd");
  }
};

export const updateProductMainLocationCapacity =
  ({ grn_line_id, product_main_location_id, capacity, is_final_capacity }) =>
  async (dispatch) => {
    try {
      await client_v2.put(
        `/product_main_locations/${product_main_location_id}`,
        {
          capacity,
          is_final_capacity,
        }
      );

      dispatch({
        type: "GRN_UPDATE_GRN_LINE_CAPACITY",
        grn_line_id,
        product_main_location_id,
        capacity,
        is_final_capacity,
      });

      toastr.success("Sukces", "Zaktualizowano pojemność");
    } catch (error) {
      toastr.error("Błąd", "Wystąpił błąd");
    }
  };

export const removeGrnLineBatch =
  ({ grn_line_id, grn_line_batch_id, deleting_user_id }) =>
  async (dispatch) => {
    try {
      await client_v2.post(`/grn_lines/${grn_line_id}/delete_batch`, {
        grn_line_batch_id,
        deleting_user_id,
      });
      const { data } = await client_v2.get(
        `/grn_lines/${grn_line_id}?context=ui_grn_show`
      );
      dispatch({
        type: "SYNC_GRN_LINE",
        data,
      });
    } catch (error) {
      console.log(error);
      toastr.error("Błąd", "Wystąpił błąd");
      throw error;
    }
  };

export const syncGrnLine = (data) => (dispatch) =>
  dispatch({ type: "SYNC_GRN_LINE", data });
