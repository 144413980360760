import React, { useMemo, useRef } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { client_v2 } from "utils/api";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line, Bar, getElementAtEvent } from "react-chartjs-2";
import { setFilter } from "actions/user_errors_stats";
import { getAllUserErrorName } from "utils/gdn_user_error_options";
import { getGrnErrorName } from "utils/grn_user_error_options";
import { ReactComponent as InfoIcon } from "icons/info.svg";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Tooltip,
  Legend,
  Filler
);

function numberWithSpaces(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

const randomNum = () => Math.floor(Math.random() * (235 - 52 + 1) + 52);

const randomRGB = () =>
  Array(3)
    .fill(null)
    .map(() => randomNum());

const getLabelDateFormat = (type) => {
  switch (type) {
    case "year":
      return "YYYY";
    case "month":
      return "MM-YYYY";
    case "hour":
      return "DD-MM-YYYY HH:mm";
    default:
      return "DD-MM-YYYY";
  }
};

const options = {
  responsive: false,
  interaction: {
    mode: "index",
    intersect: false,
  },
  stacked: false,
  scales: {
    y: {
      type: "linear",
      display: true,
      position: "left",
      min: 0,
    },
  },
};

const product_options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
};

const kinds_before_send = [
  "wrong_product",
  "wrong_product_quantity",
  "incomplete_product",
  "picking_other",
  "incorrect_missing",
];

const kinds_after_send = [
  "wrong_product",
  "wrong_product_quantity",
  "incomplete_product",
  "incorrect_photo",
  "packing_other",
  "badly_packed",
  "double_cod",
  "skip_note",
  "extra_products",
];

const Charts = ({
  data_gdn_errors,
  data_grn_errors,
  interval,
  active_user_names,
  setFilter,
}) => {
  const product_chart_ref = useRef();
  const user_chart_ref = useRef();

  const gdn_user_errors_count = data_gdn_errors.user_histogram.map(
    ({ total }) => total
  );

  const gdn_user_errors_total_count = gdn_user_errors_count.reduce(
    (acc, value) => acc + value,
    0
  );

  const grn_user_errors_count = data_grn_errors.user_histogram.map(
    ({ total }) => total
  );
  const grn_user_errors_total_count = grn_user_errors_count.reduce(
    (acc, value) => acc + value,
    0
  );

  const errors_total_count =
    gdn_user_errors_total_count + grn_user_errors_total_count;

  const chart_products = useMemo(() => {
    const products = [...data_gdn_errors.products].map(({ name, count }) => ({
      name,
      count,
    }));
    data_grn_errors.products.forEach(({ name, count }) => {
      const product = products.find((product) => product.name === name);
      if (product) {
        product.count += count;
      } else {
        products.push({ name, count });
      }
    });
    return products.filter(({ count }) => count > 2);
  }, []);

  const uniq_users = useMemo(
    () => [
      ...new Set(
        [...data_gdn_errors.users, ...data_grn_errors.users].map(
          ({ name }) => name
        )
      ),
    ],
    []
  );

  const product_colors = useMemo(
    () =>
      Array(chart_products.length)
        .fill(null)
        .map(() => randomRGB()),
    []
  );

  const worker_colors = useMemo(
    () =>
      Array(uniq_users.length)
        .fill(null)
        .map(() => randomRGB()),
    []
  );

  const users = useMemo(
    () =>
      uniq_users.map((name) => {
        const [r, g, b] = randomRGB();
        return {
          label: name,
          value: name,
          borderColor: `rgba(${r},${g},${b},1)`,
          backgroundColor: `rgba(${r},${g},${b},0.7)`,
        };
      }),
    []
  );

  const gdn_kinds = useMemo(
    () =>
      data_gdn_errors.kinds.map(({ name }) => {
        const [r, g, b] = randomRGB();
        return {
          label: getAllUserErrorName(name),
          value: name,
          borderColor: `rgba(${r},${g},${b},1)`,
          backgroundColor: `rgba(${r},${g},${b},0.7)`,
        };
      }),
    []
  );

  const grn_kinds = useMemo(
    () =>
      data_grn_errors.kinds.map(({ name }) => {
        const [r, g, b] = randomRGB();
        return {
          label: getGrnErrorName(name),
          value: name,
          borderColor: `rgba(${r},${g},${b},1)`,
          backgroundColor: `rgba(${r},${g},${b},0.7)`,
        };
      }),
    []
  );

  const getKindBeforeSendErrors = (name) =>
    data_gdn_errors.kind_histogram_before_send.map(
      ({ kinds }) => kinds?.[name] || 0
    );

  const getKindAfterSendErrors = (name) =>
    data_gdn_errors.kind_histogram_after_send.map(
      ({ kinds }) => kinds?.[name] || 0
    );

  const getKindAllGrnErrors = (name) =>
    data_grn_errors.kind_all.map(({ kinds }) => kinds?.[name] || 0);

  const active_user_histogram = users.filter(({ value }) =>
    active_user_names.includes(value)
  );

  const all_histogram_dates = [
    ...new Set(
      [
        ...data_gdn_errors.user_histogram,
        ...data_grn_errors.user_histogram,
      ].map(({ date }) => date)
    ),
  ];

  const all_histogram_data = all_histogram_dates.map((date) => {
    const gdn = data_gdn_errors.user_histogram.find(
      ({ date: d }) => d === date
    );
    const grn = data_grn_errors.user_histogram.find(
      ({ date: d }) => d === date
    );

    const users = { ...gdn?.users };

    return {
      date,
      total: (gdn?.total || 0) + (grn?.total || 0),
      users,
    };
  });

  const getUserErrors = (name) =>
    all_histogram_data.map(({ users }) => users?.[name] || 0);

  const chart_data_user = {
    labels: all_histogram_data.map(({ date }) =>
      moment(date).format(getLabelDateFormat(interval))
    ),
    datasets:
      active_user_histogram.length > 0
        ? active_user_histogram?.map(
            ({ label, value, borderColor, backgroundColor }) => ({
              label,
              data: getUserErrors(value),
              fill: false,
              borderWidth: 2,
              borderColor,
              backgroundColor,
              yAxisID: "y",
            })
          )
        : [
            {
              label: "Wszystkie",
              data: all_histogram_data.map(({ total }) => total),
              fill: false,
              borderWidth: 2,
              borderColor: "#5D78FF",
              backgroundColor: "#5D78FF",
            },
          ],
  };

  const chart_data_kind_before_send = {
    labels: data_gdn_errors.kind_histogram_before_send.map(({ date }) =>
      moment(date).format(getLabelDateFormat(interval))
    ),
    datasets: gdn_kinds
      ?.filter(({ value }) => kinds_before_send.includes(value))
      ?.map(({ label, value, borderColor, backgroundColor }) => ({
        label,
        data: getKindBeforeSendErrors(value),
        fill: false,
        borderWidth: 2,
        borderColor,
        backgroundColor,
        yAxisID: "y",
      })),
  };

  const chart_data_kind_after_send = {
    labels: data_gdn_errors.kind_histogram_after_send.map(({ date }) =>
      moment(date).format(getLabelDateFormat(interval))
    ),
    datasets: gdn_kinds
      ?.filter(({ value }) => kinds_after_send.includes(value))
      ?.map(({ label, value, borderColor, backgroundColor }) => ({
        label,
        data: getKindAfterSendErrors(value),
        fill: false,
        borderWidth: 2,
        borderColor,
        backgroundColor,
        yAxisID: "y",
      })),
  };

  const chart_data_grns = {
    labels: data_grn_errors.kind_all.map(({ date }) =>
      moment(date).format(getLabelDateFormat(interval))
    ),
    datasets: grn_kinds?.map(
      ({ label, value, borderColor, backgroundColor }) => ({
        label,
        data: getKindAllGrnErrors(value),
        fill: false,
        borderWidth: 2,
        borderColor,
        backgroundColor,
        yAxisID: "y",
      })
    ),
  };

  const chart_data_products = {
    labels: chart_products.map(({ name }) => name),
    datasets: [
      {
        label: null,
        data: chart_products.map(({ count }) => count),
        borderColor: product_colors.map(
          ([r, g, b]) => `rgba(${r},${g},${b},1)`
        ),
        backgroundColor: product_colors.map(
          ([r, g, b]) => `rgba(${r},${g},${b},0.7)`
        ),
      },
    ],
  };
  const data_user_errors = uniq_users.map((name) => {
    const gdn_count =
      data_gdn_errors.user_errors.find(
        ({ name: user_name }) => user_name === name
      )?.count || 0;
    const grn_count =
      data_grn_errors.user_errors.find(
        ({ name: user_name }) => user_name === name
      )?.count || 0;

    return {
      name,
      count: gdn_count + grn_count,
    };
  });

  const chart_data_workers = {
    labels: data_user_errors.map(({ name }) => name),
    datasets: [
      {
        label: null,
        data: data_user_errors.map(({ count }) => count),
        borderColor: worker_colors.map(([r, g, b]) => `rgba(${r},${g},${b},1)`),
        backgroundColor: worker_colors.map(
          ([r, g, b]) => `rgba(${r},${g},${b},0.7)`
        ),
      },
    ],
  };

  const onProductClick = async (event) => {
    const [el] = getElementAtEvent(product_chart_ref.current, event);
    if (el?.index > -1) {
      try {
        const sku = chart_products?.[el.index]?.name;
        const {
          data: { id },
        } = await client_v2.get(`/products/find_by_code?code=${sku}`);
        window.open(`/products/${id}`, "_blank");
      } catch (error) {
        toastr.error("Błąd", `Nie udało się pobrać informacji o produkcie`);
      }
    }
  };

  const onUserClick = async (event) => {
    const [el] = getElementAtEvent(product_chart_ref.current, event);
    if (el?.index > -1) {
      const user_name = data_user_errors?.[el.index]?.name;
      setFilter({
        histogram_user_names: [user_name],
      });
    }
  };

  return (
    <div className="dashboard">
      <header className="dashboard__header dashboard__header--edit">
        <div className="icon__container">
          <InfoIcon />
        </div>
        <h2 className="dashboard__title heading">Statystyki</h2>
      </header>
      <div className="chart-panel">
        <div className="chart-panel__body">
          <>
            <p>
              Ilość błędów:{" "}
              <strong>{numberWithSpaces(errors_total_count)}</strong>
            </p>
            <Line
              width={window.innerWidth - 160}
              height="350px"
              options={options}
              data={chart_data_user}
            />
            <h2 className="main-title center">Błędy podczas kompletacji</h2>
            <Bar
              width={window.innerWidth - 160}
              height="350px"
              options={options}
              data={chart_data_kind_before_send}
            />
            <h2 className="main-title center">Błędy podczas pakowania</h2>
            <Bar
              width={window.innerWidth - 160}
              height="350px"
              options={options}
              data={chart_data_kind_after_send}
            />
            <h2 className="main-title center">Błędy dostaw</h2>
            <Bar
              width={window.innerWidth - 160}
              height="350px"
              options={options}
              data={chart_data_grns}
            />
            <h2 className="main-title center">Błędy pracowników</h2>
            <Bar
              ref={user_chart_ref}
              style={{ marginTop: 50 }}
              width={window.innerWidth - 160}
              height="350px"
              options={product_options}
              data={chart_data_workers}
              onClick={onUserClick}
            />
            <h2 className="main-title center">
              Najbardziej problematyczne produkty
            </h2>
            <Bar
              ref={product_chart_ref}
              style={{ marginTop: 50 }}
              width={window.innerWidth - 160}
              height="350px"
              options={product_options}
              data={chart_data_products}
              onClick={onProductClick}
            />
          </>
        </div>
      </div>
    </div>
  );
};

export default connect(
  ({
    user_errors_stats: {
      data: { gdn_errors, grn_errors },
      filters,
    },
  }) => ({
    data_gdn_errors: gdn_errors,
    data_grn_errors: grn_errors,
    interval: filters.histogram_interval,
    active_user_names: filters.histogram_user_names,
  }),
  (dispatch) => ({
    setFilter: (filter) => dispatch(setFilter(filter)),
  })
)(Charts);
