import React, { useState } from "react";
import { Button, ModalDelete } from "expano-components";
import { connect } from "react-redux";
import { EditLocation } from "../modals";
import { client_v2 } from "utils/api";

import getLocationDataFromIdentifier from "utils/getLocationDataFromIdentifier";
import { createInventarizationTask } from "actions/products";
import { deleteMainLocation } from "actions/product_main_locations";

import { ReactComponent as ClockIcon } from "icons/clock.svg";
import Logs from "../modals/Logs";
import LastActivity from "../modals/LastActivity";

const getStockStatusColor = (diff) => {
  if (diff > 0) {
    return "stock-positive";
  }
  if (diff < 0) {
    return "stock-negative";
  }

  return "stock-neutral";
};

const LineItem = ({
  id,
  product_id,
  stock,
  stock_diff = 0,
  warehouse,
  warehouse_position,
  has_pending_inventarization_task,
  createInventarizationTask,
  deleteMainLocation,
}) => {
  const [is_adding_task, setIsAddingTask] = useState(false);
  const [shown_last_activity, showLastActivity] = useState(false);
  const [shown_logs, showLogs] = useState(false);
  const [shown_edit, showEdit] = useState(false);
  const [shown_delete, showDelete] = useState(false);
  const [real_stock, setRealStock] = useState("b/d");

  const location_data = getLocationDataFromIdentifier(
    warehouse_position?.identifier
  );

  const getRealStock = async () => {
    try {
      const { data } = await client_v2.get(
        `/products/${product_id}/real_stock?warehouse_id=${warehouse.id}`
      );
      setRealStock(data.real_stock);
    } catch (error) {}
  };

  const handleCreateTask = async () => {
    try {
      setIsAddingTask(true);
      await createInventarizationTask({
        product_main_location_id: id,
        product_id,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsAddingTask(false);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteMainLocation(id);
    } catch (error) {
      console.log(error);
    } finally {
      showDelete(false);
    }
  };

  return (
    <li className="product-localizations__item">
      {shown_delete && (
        <ModalDelete
          handleDelete={handleDelete}
          handleClose={() => showDelete(false)}
          name={`lokalizację ${warehouse_position.identifier}`}
        />
      )}
      {shown_edit && (
        <EditLocation
          handleClose={() => showEdit(false)}
          id={id}
          initial_warehouse_position_id={warehouse_position?.id}
        />
      )}
      {shown_logs && <Logs handleClose={() => showLogs(false)} id={id} />}
      {shown_last_activity && (
        <LastActivity
          handleClose={() => showLastActivity(false)}
          id={warehouse_position?.id}
        />
      )}
      <div className="text">
        <p className="name">{warehouse?.name}</p>
        <ul className="address">
          <li>Pomieszczenie {location_data?.room || "-"}</li>
          <li>Alejka {location_data?.aisle || "-"}</li>
          <li>Regał {location_data?.rack}</li>
          <li>Półka {location_data?.shelf}</li>
          <li>Pozycja {location_data?.position}</li>
        </ul>
        <div className="stock">
          <p>
            Ilość na półce: <span>{real_stock}</span>{" "}
            <button type="button" onClick={getRealStock}>
              <ClockIcon />
            </button>
          </p>
          <p>
            Na stanie:{" "}
            <span>
              {typeof stock === "number" ? (
                <>
                  {stock} szt.
                  <span className={getStockStatusColor(stock_diff)}>
                    ({stock_diff > 0 ? "+" : ""}
                    {stock_diff})
                  </span>
                </>
              ) : (
                "b/d"
              )}
            </span>
          </p>
        </div>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Button
          style={{ marginRight: 10 }}
          type="add"
          disabled={has_pending_inventarization_task || is_adding_task}
          text={
            has_pending_inventarization_task
              ? "Task utworzony"
              : "Utwórz task inwentaryzacyjny"
          }
          onClick={handleCreateTask}
        />
        <Button
          style={{ marginRight: 10 }}
          text="Historia inwentaryzacji"
          onClick={() => showLogs(true)}
        />
        <Button
          text="Ostatnia aktywność"
          onClick={() => showLastActivity(true)}
        />
        <Button type="edit" onClick={() => showEdit(true)} />
        {warehouse_position?.identifier !== "B01B01-R01P01M01" && (
          <Button type="delete-bin" onClick={() => showDelete(true)} />
        )}
      </div>
    </li>
  );
};

export default connect(null, (dispatch) => ({
  createInventarizationTask: (id) => dispatch(createInventarizationTask(id)),
  deleteMainLocation: (id) => dispatch(deleteMainLocation(id)),
}))(LineItem);
