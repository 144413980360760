import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import ReactDOM from "react-dom";
import { Button, Input } from "expano-components";

import { updateInventorizationTaskDiff } from "actions/inventorizations";

const InputField = (props) => <Input {...props} />;

const validate = ({ inventorization_stock_diff }) => {
  const errors = {};

  const is_number = /^[+-]?(\d+(\.\d*)?|\.\d+)$/.test(
    inventorization_stock_diff
  );
  if (inventorization_stock_diff !== "") {
    if (!is_number) {
      errors.inventorization_stock_diff = "Wprowadź poprawną wartość!";
    }
  }

  return errors;
};

let timeout;

const UpdateTaskDiffModal = ({
  handleSubmit,
  handleClose,
  updateInventorizationTaskDiff,
  id,
  product_id,
}) => {
  const ref = useRef(null);
  const [active, setActive] = useState(false);

  useEffect(() => {
    timeout = setTimeout(() => {
      setActive(true);
      const input = ref.current.querySelector("input");
      if (input) {
        input.select();
      }
    }, 200);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const onSubmit = async ({ inventorization_stock_diff }) => {
    try {
      await updateInventorizationTaskDiff(id, product_id, {
        inventorization_stock_diff,
      });
    } catch (error) {
    } finally {
      handleClose();
    }
  };
  return ReactDOM.createPortal(
    <div className="popup-wrapper">
      <form
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        className={`popup ${active ? "active" : ""}`}
      >
        <header className="popup__header">
          <h2 className="dashboard__title heading">Aktualizuj różnicę</h2>
          <Button type="close" onClick={handleClose} />
        </header>
        <div className="popup__body">
          <div className="popup-text" ref={ref}>
            <Field
              type="text"
              id="inventorization_stock_diff"
              name="inventorization_stock_diff"
              component={InputField}
            />
          </div>
        </div>
        <div
          className="popup__footer"
          style={{
            padding: "10px 15px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button type="cancel" text="Anuluj" onClick={handleClose} />
          <button
            type="submit"
            onClick={handleSubmit(onSubmit)}
            className="btn btn-sm btn-green"
          >
            <span>Zapisz</span>
          </button>
        </div>
      </form>
    </div>,
    document.getElementById("root")
  );
};

export default connect(null, (dispatch) => ({
  updateInventorizationTaskDiff: (id, product_id, values) =>
    dispatch(updateInventorizationTaskDiff(id, product_id, values)),
}))(
  reduxForm({
    form: "warehouse_task_update_inventorization_stock_diff",
    validate,
    enableReinitialize: true,
  })(UpdateTaskDiffModal)
);
