const available_warehouse_ids = [1, 6];
const millto_warehouse_id = 10;

const checkDeliveryKind = ({
  is_simplified,
  kind,
  warehouse_id,
  valid_for_grn_line_batches,
}) => {
  switch (true) {
    case kind === "delivery" && millto_warehouse_id === warehouse_id:
      return "millto_delivery";
    case is_simplified:
      // Tradecron Milto
      return "simplified";
    case kind === "shift" && valid_for_grn_line_batches:
      return "shift_batches";
    case kind === "shift" && !valid_for_grn_line_batches:
      return "shift";
    case kind === "delivery" && available_warehouse_ids.includes(warehouse_id):
      return "delivery_to_main_warehouse";
    case kind === "delivery":
      return "delivery";
    default:
      return null;
  }
};

export default checkDeliveryKind;
