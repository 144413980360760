import React, { useEffect, useState } from "react";
import moment from "moment";
import classNames from "classnames";
import { Select, Checkbox } from "expano-components";
import { connect } from "react-redux";
import LazyLoad from "react-lazy-load";
import { Main } from "layouts";
import {
  getInventorization,
  createInventorizationTasks,
  setSearchValue,
  setShowOnlyErrored,
  paginateTasks,
  sortTasks,
} from "actions/inventorizations";
import MainTable from "components/MainTable";
import { getWarehouseTaskStatus } from "utils/warehouse_tasks";
import { ReactComponent as UserIcon } from "icons/user.svg";
import { ReactComponent as ClockIcon } from "icons/clock.svg";
import { ReactComponent as PackageIcon } from "icons/product.svg";
import Pagination from "./components/Pagination";
import UpdateTaskDiffModal from "./components/UpdateTaskDiffModal";
// import UpdateTaskQuantityModal from "./components/UpdateTaskQuantityModal";

const getStatus = (item_1, item_2) => {
  if (
    item_1.status === "completed" &&
    item_2.status === "completed" &&
    item_1.inventorization_stock_diff === item_2.inventorization_stock_diff
  ) {
    return ["success", "success"];
  }
  if (
    item_1.status === "completed" &&
    item_2.status === "completed" &&
    item_1.inventorization_stock_diff !== item_2.inventorization_stock_diff
  ) {
    return ["danger", "danger"];
  }

  return [item_1.user?.id ? "warning" : "", item_2.user?.id ? "warning" : ""];
};

const InwItem = ({
  id,
  inventorization_stock_diff,
  status,
  user,
  completed_at,
}) => {
  const label = getWarehouseTaskStatus(status);
  return (
    <div key={id} className="inw-item">
      <input
        style={{ width: 80 }}
        readOnly
        step="1"
        value={
          inventorization_stock_diff > 0
            ? `+${inventorization_stock_diff}`
            : inventorization_stock_diff
        }
        type="text"
      />
      <div className="inw-item__info">
        <p className={`status-label status-label--sm ${label.status}`}>
          <span>{label.status_text}</span>
        </p>
        <p>
          <span className="icon">
            <UserIcon />
          </span>
          <span className="text">{user?.full_name || "-"}</span>
        </p>
        <p>
          <span className="icon">
            <ClockIcon />
          </span>
          <span className="text">
            {completed_at
              ? moment(completed_at).format("YYYY.MM.DD HH:mm")
              : "-"}
          </span>
        </p>
      </div>
    </div>
  );
};

const InwActiveItems = ({ item_1, item_2, product_id }) => {
  const label_1 = getWarehouseTaskStatus(item_1.status);
  const label_2 = getWarehouseTaskStatus(item_2.status);
  const [status_1, status_2] = getStatus(item_1, item_2);

  const [warehouse_task_to_update, setWarehouseTaskToUpdate] = useState(null);

  return (
    <>
      {/* {warehouse_task_to_update?.id && (
        <UpdateTaskQuantityModal
          id={warehouse_task_to_update?.id}
          product_id={product_id}
          initialValues={{
            quantity: "",
          }}
          handleClose={() => setWarehouseTaskToUpdate(null)}
        />
      )} */}
      {warehouse_task_to_update?.id && (
        <UpdateTaskDiffModal
          id={warehouse_task_to_update?.id}
          product_id={product_id}
          initialValues={{
            inventorization_stock_diff:
              warehouse_task_to_update.inventorization_stock_diff,
          }}
          handleClose={() => setWarehouseTaskToUpdate(null)}
        />
      )}
      <div className={`inw-item ${status_1}`}>
        <input
          style={{ width: 80 }}
          readOnly
          step="1"
          value={`${item_1.inventorization_stock_diff > 0 ? "+" : ""}${
            item_1.inventorization_stock_diff === null
              ? ""
              : item_1.inventorization_stock_diff
          }`}
          type="text"
          onClick={() =>
            setWarehouseTaskToUpdate({
              id: item_1.id,
              inventorization_stock_diff: item_1.inventorization_stock_diff,
            })
          }
        />
        <div className="inw-item__info">
          <p className={`status-label status-label--sm ${label_1.status}`}>
            <span>{label_1.status_text}</span>
          </p>
          <p>
            <span className="icon">
              <UserIcon />
            </span>
            <span className="text">{item_1.user?.full_name || "-"}</span>
          </p>
          <p>
            <span className="icon">
              <ClockIcon />
            </span>
            <span className="text">
              {item_1.completed_at
                ? moment(item_1.completed_at).format("YYYY.MM.DD HH:mm")
                : "-"}
            </span>
          </p>
        </div>
      </div>
      <div className={`inw-item ${status_2}`}>
        <input
          style={{ width: 80 }}
          readOnly
          step="1"
          value={`${item_2.inventorization_stock_diff > 0 ? "+" : ""}${
            item_2.inventorization_stock_diff === null
              ? ""
              : item_2.inventorization_stock_diff
          }`}
          type="text"
          onClick={() =>
            setWarehouseTaskToUpdate({
              id: item_2.id,
              inventorization_stock_diff: item_2.inventorization_stock_diff,
            })
          }
        />
        <div className="inw-item__info">
          <p className={`status-label status-label--sm ${label_2.status}`}>
            <span>{label_2.status_text}</span>
          </p>
          <p>
            <span className="icon">
              <UserIcon />
            </span>
            <span className="text">{item_2.user?.full_name || "-"}</span>
          </p>
          <p>
            <span className="icon">
              <ClockIcon />
            </span>
            <span className="text">
              {item_2.completed_at
                ? moment(item_2.completed_at).format("YYYY.MM.DD HH:mm")
                : "-"}
            </span>
          </p>
        </div>
      </div>
    </>
  );
};

const InventorizationTasks = ({
  match: {
    params: { id },
  },
  inventorization,
  getInventorization,
  createInventorizationTasks,
  setSearchValue,
  setShowOnlyErrored,
  paginateTasks,
  sortTasks,
}) => {
  const [is_creating_tasks, setIsCreatingTasks] = useState(false);
  useEffect(() => {
    getInventorization(id);
  }, [id]);

  if (inventorization.status === "failure") {
    return <p>Error</p>;
  }

  const {
    status,
    data,
    search_value,
    show_only_errored,
    paginated_tasks,
    meta,
  } = inventorization;

  const handleCreateTasks = async () => {
    try {
      setIsCreatingTasks(true);
      await createInventorizationTasks(id);
    } catch (error) {
      console.log(error);
    } finally {
      setIsCreatingTasks(false);
    }
  };

  return (
    <Main
      is_loading={["invalid", "loading"].includes(inventorization.status)}
      page={{
        name: data?.name,
        icon: <PackageIcon />,
        breadcrumbs: [
          {
            name: "Inwentaryzacje",
            path: "/inventorizations",
          },
          { name: data?.name },
        ],
      }}
    >
      <div className="dashboard">
        <div className="progress-bar" style={{ width: "100%" }}>
          <div
            className="progress-bar__inner"
            style={{
              width: data?.meta?.progress ? `${data.meta.progress}%` : 0,
            }}
          />
        </div>
        <div className="dashboard__header row jc-sb">
          <div>
            <strong>{data?.name}</strong>
            <p>
              Magazyn: <strong>{data?.warehouse?.name}</strong>
            </p>
          </div>
          <div className="row">
            <div className="p-r-20">
              <p>Podsumowanie ilości:</p>
              <p>
                <strong>
                  {data?.meta?.total_plus_prices_diff} -{" "}
                  {data?.meta?.total_minus_prices_diff} ={" "}
                  {data?.meta?.total_sum_prices_diff}
                </strong>
              </p>
            </div>
            <button
              disabled={is_creating_tasks}
              className="btn btn-blue btn-sm"
              type="button"
              onClick={handleCreateTasks}
            >
              <span>
                {is_creating_tasks ? "Proszę czekać..." : "Utwórz taski"}
              </span>
            </button>
          </div>
        </div>
        <div className="inventorization-table">
          <div className="inventorization-table__search row">
            <div className="box-3">
              <div className="form-group">
                <div className="inp-wrapper">
                  <label htmlFor="email" className="label-form">
                    Wyszukaj
                  </label>
                  <div className="inp-group">
                    <input
                      type="text"
                      id="search_value"
                      name="search_value"
                      placeholder="SKU, nazwa produktu, lokalizacja"
                      onChange={({ target: { value } }) =>
                        setSearchValue(value)
                      }
                      value={search_value}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="box-3">
              <div className="form-group">
                <div className="inp-wrapper">
                  <label htmlFor="email" className="label-form">
                    Sortowanie
                  </label>
                  <div className="inp-group">
                    <Select
                      handleChange={(value) => sortTasks(value)}
                      options={[
                        {
                          label: "Pozycja rosnąco",
                          value: "position_asc",
                        },
                        {
                          label: "Pozycja malejąco",
                          value: "position_desc",
                        },
                        {
                          label: "Wynik (koszt) rosnąco",
                          value: "result_asc",
                        },
                        {
                          label: "Wynik (koszt) malejąco",
                          value: "result_desc",
                        },
                      ]}
                      defaultValue={{
                        label: "Pozycja rosnąco",
                        value: "position_asc",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="box-3">
              <div className="form-group">
                <div className="inp-wrapper">
                  <div className="inp-group m-t-5">
                    <Checkbox
                      meta={{}}
                      input={{
                        name: "show_only_errored",
                        value: show_only_errored,
                        onChange: () => setShowOnlyErrored(!show_only_errored),
                      }}
                      label="Pokaż tylko nieprzeliczone"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <MainTable
            is_loading={["invalid", "loading"].includes(status.status)}
            is_searching={status.status === "searching"}
            filters_open={true}
            data={paginated_tasks}
            sort={{}}
            head={[
              {
                label: "Produkt",
                name: "product",
                sortable: false,
                style: { width: "25%" },
              },
              {
                label: "Lokalizacja",
                name: "location",
                sortable: false,
                style: { width: "15%" },
              },
              {
                label: "Historia inwentaryzacji",
                name: "history",
                sortable: false,
                style: { width: "35%" },
              },
              {
                label: "Inwentaryzacje aktywne",
                name: "active",
                sortable: false,
                style: { width: "15%" },
              },
              {
                label: "Wynik",
                name: "result",
                sortable: false,
                style: { width: "10%" },
              },
            ]}
            empty_text="Brak tasków"
            renderRow={({
              product,
              inventorization_stock_diff_result,
              product_main_location,
              warehouse_inventorization_tasks,
              inventorization_prices_diff_result,
            }) => {
              const active_inventorization_tasks =
                warehouse_inventorization_tasks.slice(-2);
              const history_inventorization_tasks =
                warehouse_inventorization_tasks.slice(0, -2);
              return (
                <tr key={product.id}>
                  <td>
                    <div className="row ai-c">
                      <div style={{ minWidth: 50 }}>
                        {product?.image_url && (
                          <LazyLoad>
                            <img
                              src={product.image_url}
                              alt={product.name}
                              style={{ maxWidth: 50 }}
                            />
                          </LazyLoad>
                        )}
                      </div>
                      <div className="p-l-10 p-r-10">
                        <p>{product.name}</p>
                        <p>
                          <a
                            className="link"
                            target="_blank"
                            href={`/products/${product.id}`}
                          >
                            <strong>{product.sku}</strong>
                          </a>
                        </p>
                        {product.supplier_code && (
                          <p>
                            <strong>{product.supplier_code}</strong>
                          </p>
                        )}
                      </div>
                    </div>
                  </td>
                  <td>
                    {product_main_location?.warehouse_position?.identifier}
                  </td>
                  <td>
                    <div className="inw-items">
                      {history_inventorization_tasks.map((item) => (
                        <InwItem {...item} />
                      ))}
                    </div>
                  </td>
                  <td>
                    <div className="inw-items">
                      <InwActiveItems
                        item_1={active_inventorization_tasks[0]}
                        item_2={active_inventorization_tasks[1]}
                        product_id={product.id}
                      />
                    </div>
                  </td>
                  <td>
                    <strong
                      className={classNames({
                        "text-14": true,
                        "text-success": inventorization_stock_diff_result >= 0,
                        "text-danger": inventorization_stock_diff_result < 0,
                      })}
                    >
                      {inventorization_stock_diff_result > 0 ? "+" : ""}
                      {inventorization_stock_diff_result}
                      {inventorization_prices_diff_result
                        ? ` (${
                            inventorization_prices_diff_result > 0 ? "+" : ""
                          }${inventorization_prices_diff_result}zł)`
                        : ""}
                    </strong>
                  </td>
                </tr>
              );
            }}
          />
          <Pagination
            {...meta}
            handlePaginate={(page) => {
              paginateTasks(page);
              window.scrollTo(0, 0);
            }}
          />
        </div>
      </div>
    </Main>
  );
};

export default connect(
  ({ inventorization }) => ({ inventorization }),
  (dispatch) => ({
    getInventorization: (id) => dispatch(getInventorization(id)),
    createInventorizationTasks: (id) =>
      dispatch(createInventorizationTasks(id)),
    setSearchValue: (value) => dispatch(setSearchValue(value)),
    setShowOnlyErrored: (value) => dispatch(setShowOnlyErrored(value)),
    paginateTasks: (page) => dispatch(paginateTasks(page)),
    sortTasks: (sort) => dispatch(sortTasks(sort)),
  })
)(InventorizationTasks);
