import React, { useState } from "react";
import moment from "moment";
import { Button } from "expano-components";
import { AddLocation } from "./modals";
import LineItem from "./components/LineItem";

import { ReactComponent as LocationIcon } from "icons/location.svg";

const Localizations = ({ data }) => {
  const [shown, showModal] = useState(false);
  const [type, setType] = useState(null);

  return (
    <>
      {shown && (
        <AddLocation
          type={type}
          handleClose={() => {
            showModal(false);
            setType(null);
          }}
        />
      )}
      <div className="dashboard m-b-20">
        <header className="dashboard__header dashboard__header--edit">
          <div className="icon__container">
            <LocationIcon />
          </div>
          <h2 className="dashboard__title heading">Lokalizacje główne</h2>
          <Button
            type="add"
            text="Dodaj lokalizacje"
            onClick={() => {
              showModal(true);
              setType("main");
            }}
          />
        </header>
        <ul className="product-localizations">
          {data?.product_main_locations?.map((item) => (
            <LineItem
              key={item.id}
              {...item}
              product_id={data.id}
              type="main"
            />
          ))}
        </ul>
      </div>
      <div className="dashboard">
      <header className="dashboard__header dashboard__header--edit">
        <div className="icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20">
            <path fill="none" d="M0,0H20V20H0Z" />
            <path
              fill="#5d78ff"
              d="M1.423,6.423A.833.833,0,1,1,.244,5.244l5-5A.833.833,0,0,1,6.4.219l5,4.583A.833.833,0,1,1,10.27,6.031L5.858,1.987Z"
              transform="translate(10.833 4.167) rotate(90)"
            />
            <rect
              fill="#5d78ff"
              opacity="0.3"
              width="8.333"
              height="1.667"
              rx="0.833"
              transform="translate(10 14.167)"
            />
          </svg>
        </div>
        <h2 className="dashboard__title heading">
          Logi lokalizacji
        </h2>
      </header>
      {data?.logs?.length > 0 && (
        <ul className="logs-list">
          {data.logs
            .sort(
              (item, next) =>
                new Date(next.created_at).getTime() -
                new Date(item.created_at).getTime()
            )
            .map(({ id, created_at, content, user }) => (
              <li className="logs-list__item" key={id}>
                <p className="title">
                  {created_at
                    ? moment(created_at).format(
                        "DD-MM-YYYY HH:mm"
                      )
                    : ""}{" "}
                  {user?.id
                    ? `${user?.first_name} ${user?.last_name}`
                    : "brak"}
                </p>
                <p>{content}</p>
              </li>
            ))}
        </ul>
      )}
    </div>
    </>
  );
};

export default Localizations;
