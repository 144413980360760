import { toastr } from "react-redux-toastr";
import { client_v2, stringifyQuery } from "utils/api";

export const getUserErrorsStats = () => async (dispatch, getState) => {
  try {
    const { status, filters } = getState().user_errors_stats;
    const query = stringifyQuery(filters);

    dispatch({
      type:
        status === "invalid"
          ? "USER_ERRORS_STATS_LOADING"
          : "USER_ERRORS_STATS_UPDATING",
    });
    const { data } = await client_v2.get(`/stats/user_errors?${query}`);
    dispatch({
      type: "USER_ERRORS_STATS_SUCCESS",
      data,
    });
  } catch (error) {
    dispatch({ type: "USER_ERRORS_STATS_FAILURE" });
    toastr.error("Błąd", "Wystąpił błąd podczas pobierania danych");
    throw error;
  }
};

export const setFilter = (filter) => (dispatch) =>
  dispatch({
    type: "USER_ERRORS_STATS_SET_FILTER",
    filter,
  });
