import React, { useState } from "react";
import { ModalPreview } from "expano-components";
import LazyLoad from "react-lazy-load";
import classNames from "classnames";
import ReactDOM from "react-dom";
import moment from "moment";
import Status from "./components/Status";
import Action from "./components/Action";

// new picked packed missing

import StyledGdnLine from "./styles";

const GdnLine = ({
  id,
  status,
  quantity,
  product,
  is_potentially_missing,
  potentially_missing_quantity,
  missing_quantity,
  disabled = false,
  gdn_warehouse_id,
  zIndex,
  marked_as_missing_at,
}) => {
  const [is_modal_open, openModal] = useState(false);
  const image_url = product.image_url;

  const product_location_identifier = product?.product_main_locations?.find(
    (product_main_location) =>
      product_main_location?.warehouse_id === gdn_warehouse_id
  )?.warehouse_position?.identifier;

  return (
    <StyledGdnLine
      className={classNames(product.sku, { disabled })}
      style={{ zIndex }}
      key={id}
    >
      <div className="gdn-line__main">
        <div className="preview">
          <LazyLoad width="130" height="130">
            <button
              className="btn-preview"
              onClick={() => openModal(true)}
              style={{
                backgroundImage: image_url
                  ? `url(${product?.image_thumb_url || image_url})`
                  : "",
              }}
            />
          </LazyLoad>
        </div>
        <div className="product-info">
          <p className="sku">{product?.sku}</p>
          <p className="name">{product?.name}</p>
          <p className="codes">
            <span>KOD: {product?.producer_code || "-"}</span>
          </p>
          {marked_as_missing_at && (
            <p className="codes">
              <span>Lokalizacja: {product_location_identifier || "-"}</span>
            </p>
          )}
        </div>
        <div className="quantity">
          <span className="quantity__value">
            {quantity ? Number(quantity).toFixed(0) : "-"}
          </span>
        </div>
        <Action
          missing_quantity={missing_quantity}
          product={product}
          status={status}
          id={id}
        />
      </div>
      <div className="gdn-line__statuses">
        {is_potentially_missing && (
          <Status
            type="danger"
            text={`Zgłoszono brak podczas kompletacji (${parseInt(
              potentially_missing_quantity
            )} szt.)`}
          />
        )}
        {product?.is_packer_alert_enabled && (
          <Status type="info" text={product?.packer_alert} />
        )}
        {!!marked_as_missing_at && (
          <Status
            type="danger"
            text={`Data zgłoszenia braku ${moment(marked_as_missing_at).format(
              "DD.MM.YYYY HH:mm"
            )}`}
          />
        )}
      </div>
      {is_modal_open &&
        ReactDOM.createPortal(
          <ModalPreview
            handleClose={() => {
              openModal(false);
            }}
            src={image_url}
            alt={product?.name}
          />,
          document.getElementById("root")
        )}
    </StyledGdnLine>
  );
};

export default GdnLine;
