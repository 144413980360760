import React, { useState } from "react";
import { connect } from "react-redux";
import { Button } from "expano-components";
import { createMM, finishGrn } from "actions/grns";

const InfoText = ({ finishGrn, createMM, data }) => {
  const [is_loading, setLoading] = useState(false);
  const [is_finishing, setFinishing] = useState(false);

  const handleCreateMM = async () => {
    try {
      setLoading(true);
      await createMM(data?.id);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleFinish = async () => {
    try {
      setFinishing(true);
      await finishGrn(data?.id);
    } catch (error) {
    } finally {
      setFinishing(false);
    }
  };

  if (data?.status === "waiting_for_shift") {
    return (
      <div className="box-text">
        <p className="m-b-5">
          To zamówienie oczekuje na utworzenie MM, nie możesz go edytować.
        </p>
        <div className="row">
          <Button
            disabled={is_loading}
            type="add"
            text={is_loading ? "Trwa generowanie" : "Utwórz MM"}
            onClick={handleCreateMM}
          />
          <button
            type="button"
            disabled={is_loading}
            class="btn btn-sm btn-blue m-l-10"
            onClick={handleFinish}
            style={{ backgroundColor: "#f9d101" }}
          >
            <span>{is_finishing ? "Proszę czekać" : "Zakończ dostawę"}</span>
          </button>
        </div>
      </div>
    );
  }
  if (data?.status === "finished") {
    return (
      <p className="box-text">
        To zamówienie jest zakończone, nie możesz go edytować
        <br />
        Do zamówienia utworzono MM o numerze: {data?.shift_identifier}
      </p>
    );
  }
  if (
    data?.grn_lines.filter(({ counting_state }) => counting_state === "pending")
      .length > 0
  ) {
    return (
      <p className="box-text">
        Możesz potwierdzić tylko uzupełnione zamówienie
      </p>
    );
  }

  if (
    data?.grn_lines.filter(({ counting_state }) => counting_state === "correct")
      .length === data?.grn_lines?.length &&
    !data?.has_other_error
  ) {
    return (
      <p className="box-text">
        Wszystkie pozycje zostały poprawnie dostarczone i przyjęte
      </p>
    );
  }

  return null;
};

export default connect(
  ({ grn }) => ({ data: grn?.data }),
  (dispatch) => ({
    createMM: (id) => dispatch(createMM(id)),
    finishGrn: (id) => dispatch(finishGrn(id)),
  })
)(InfoText);
