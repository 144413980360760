const initialState = {
  status: "invalid",
  search_value: "",
  sort: "position_asc",
  show_only_errored: false,
  all_tasks: [],
  meta: {
    page: 1,
    per_page: 100,
    total_pages: 1,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "INVENTORIZATION_LOADING":
      return {
        ...state,
        status: "loading",
      };
    case "INVENTORIZATION_SUCCESS":
      return {
        ...state,
        status: "success",
        data: { ...action.data, meta: action.meta },
        all_tasks: action.tasks,
        paginated_tasks: action.tasks.slice(0, state.meta.per_page),
        meta: {
          ...state.meta,
          total_pages: Math.ceil(action.tasks.length / state.meta.per_page),
        },
      };
    case "INVENTORIZATION_FAILURE":
      return {
        ...state,
        status: "failure",
      };
    case "INVENTORIZATION_FILTER_TASKS": {
      let filtered_tasks =
        state.search_value.length > 0
          ? state.all_tasks.filter(
              ({
                product_main_location: {
                  warehouse_position: { identifier },
                },
                product: { name, sku, supplier_code },
              }) =>
                identifier
                  .toLowerCase()
                  .includes(state.search_value.toLowerCase()) ||
                name.toLowerCase().includes(state.search_value.toLowerCase()) ||
                sku.toLowerCase().includes(state.search_value.toLowerCase()) ||
                (supplier_code &&
                  supplier_code
                    .toLowerCase()
                    .includes(state.search_value.toLowerCase()))
            )
          : state.all_tasks;

      if (state.show_only_errored) {
        filtered_tasks = filtered_tasks.filter(
          ({ inventorization_stock_diff_result }) =>
            inventorization_stock_diff_result === null
        );
      }
      return {
        ...state,
        paginated_tasks: filtered_tasks.slice(0, state.meta.per_page),
        meta: {
          ...state.meta,
          page: 1,
          per_page: state.meta.per_page,
          total_pages: Math.ceil(filtered_tasks.length / state.meta.per_page),
        },
      };
    }
    case "INVENTORIZATION_PAGINATE_TASKS": {
      let filtered_tasks =
        state.search_value.length > 0
          ? state.all_tasks.filter(
              ({
                product_main_location: {
                  warehouse_position: { identifier },
                },
                product: { name, sku, supplier_code },
              }) =>
                identifier
                  .toLowerCase()
                  .includes(state.search_value.toLowerCase()) ||
                name.toLowerCase().includes(state.search_value.toLowerCase()) ||
                sku.toLowerCase().includes(state.search_value.toLowerCase()) ||
                (supplier_code &&
                  supplier_code
                    .toLowerCase()
                    .includes(state.search_value.toLowerCase()))
            )
          : state.all_tasks;

      if (state.show_only_errored) {
        filtered_tasks = filtered_tasks.filter(
          ({ inventorization_stock_diff_result }) =>
            inventorization_stock_diff_result === null
        );
      }
      return {
        ...state,
        paginated_tasks: filtered_tasks.slice(
          (action.page - 1) * state.meta.per_page,
          action.page * state.meta.per_page
        ),
        meta: {
          ...state.meta,
          page: action.page,
        },
      };
    }
    case "INVENTORIZATION_SORT_TASKS": {
      if (action.sort === "result_asc") {
        const tasks = [...state.all_tasks];

        const task_without_prices = tasks.filter(
          ({ inventorization_prices_diff_result }) =>
            inventorization_prices_diff_result === null
        );
        const task_with_prices = tasks
          .filter(
            ({ inventorization_prices_diff_result }) =>
              inventorization_prices_diff_result !== null
          )
          .sort(
            (a, b) =>
              Number(a.inventorization_prices_diff_result) -
              Number(b.inventorization_prices_diff_result)
          );
        const all_tasks = [...task_with_prices, ...task_without_prices];
        return {
          ...state,
          sort: action.sort,
          all_tasks,
        };
      }
      if (action.sort === "result_desc") {
        const tasks = [...state.all_tasks];

        const task_without_prices = tasks.filter(
          ({ inventorization_prices_diff_result }) =>
            inventorization_prices_diff_result === null
        );
        const task_with_prices = tasks
          .filter(
            ({ inventorization_prices_diff_result }) =>
              inventorization_prices_diff_result !== null
          )
          .sort(
            (a, b) =>
              Number(b.inventorization_prices_diff_result) -
              Number(a.inventorization_prices_diff_result)
          );
        const all_tasks = [...task_with_prices, ...task_without_prices];
        return {
          ...state,
          sort: action.sort,
          all_tasks,
        };
      }
      if (action.sort === "position_asc") {
        const all_tasks = [...state.all_tasks].sort((a, b) =>
          a.product_main_location?.warehouse_position?.identifier?.localeCompare(
            b.product_main_location?.warehouse_position?.identifier
          )
        );
        return {
          ...state,
          sort: action.sort,
          all_tasks,
        };
      }
      if (action.sort === "position_desc") {
        const all_tasks = [...state.all_tasks].sort((a, b) =>
          b.product_main_location?.warehouse_position?.identifier?.localeCompare(
            a.product_main_location?.warehouse_position?.identifier
          )
        );
        return {
          ...state,
          sort: action.sort,
          all_tasks,
        };
      }
    }
    case "INVENTORIZATION_UPDATE_TASK": {
      const all_tasks = [...state.all_tasks];

      const task = all_tasks.find(
        ({ product: { id } }) => id === action.product_id
      );
      if (task) {
        const warehouse_inventorization_task =
          task.warehouse_inventorization_tasks.find(
            ({ id }) => id === action.id
          );
        if (warehouse_inventorization_task) {
          warehouse_inventorization_task.inventorization_stock_diff =
            action.inventorization_stock_diff;
        }
      }
      return {
        ...state,
        all_tasks,
      };
    }
    case "INVENTORIZATION_SET_SEARCH_VALUE": {
      return {
        ...state,
        search_value: action.value,
      };
    }
    case "INVENTORIZATION_SET_SHOW_ONLY_ERRORED": {
      return {
        ...state,
        show_only_errored: action.value,
      };
    }
    default:
      return state;
  }
};
