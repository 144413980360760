import React, { useState } from "react";
import { connect } from "react-redux";
import Item from "./components/Item";
import { Button } from "expano-components";
import CreateIssueModal from "./components/CreateIssueModal";

const GdnErrors = ({ id, is_completed, gdn_user_errors }) => {
  const [is_open_issue_modal, setOpenIssueModal] = useState(false);

  return (
    <div className="dashboard m-b-20">
      <header className="dashboard__header dashboard__header--edit">
        <h2 className="dashboard__title heading">Błędy zamówienia</h2>
        {is_completed && (
          <Button
            type="add"
            text="Zgłoś błąd zamówienia"
            onClick={() => setOpenIssueModal(true)}
          />
        )}
      </header>
      {is_open_issue_modal && (
        <CreateIssueModal
          gdn_id={id}
          handleClose={() => setOpenIssueModal(false)}
        />
      )}

      <ul className="dashboard__list">
        {gdn_user_errors
          .filter(({ kind }) =>
            [
              "incorrect_photo",
              "packing_other",
              "picking_other",
              "badly_packed",
              "double_cod",
              "skip_note",
              "extra_products",
            ].includes(kind)
          )
          ?.map((item) => (
            <Item key={item?.id} {...item} />
          ))}
      </ul>
    </div>
  );
};

const mapStateToProps = ({
  gdn: {
    data: { gdn_user_errors, status },
  },
}) => ({
  gdn_user_errors,
  is_completed: status === "completed",
});

export default connect(mapStateToProps, null)(GdnErrors);
