import { client_v2, getApiErrorMessage } from "utils/api";
import { toastr } from "react-redux-toastr";

export const createMainLocation =
  ({ product_id, warehouse_position_id }) =>
  async (dispatch) => {
    try {
      const {
        data: { id },
      } = await client_v2.post("/product_main_locations", {
        product_id,
        warehouse_position_id,
      });
      const { data } = await client_v2.get(
        `/product_main_locations/${id}?context=ui_product_show`
      );

      dispatch({
        type: "EDIT_PRODUCT_ADD_MAIN_LOCATION",
        data,
      });

      toastr.success("Sukces", "Lokalizacja została dodana");
    } catch (error) {
      toastr.error("Błąd", getApiErrorMessage(error));
      throw error;
    }
  };

export const updateMainLocation = (id, values) => async (dispatch) => {
  try {
    await client_v2.put(`/product_main_locations/${id}`, values);
    const { data } = await client_v2.get(
      `/product_main_locations/${id}?context=ui_product_show`
    );
    dispatch({
      type: "EDIT_PRODUCT_UPDATE_MAIN_LOCATION",
      data,
    });
    toastr.success("Sukces", "Lokalizacja została zaktualizowana");
  } catch (error) {
    toastr.error("Błąd", getApiErrorMessage(error));
    throw error;
  }
};

export const deleteMainLocation =
  (product_main_location_id) => async (dispatch) => {
    try {
      await client_v2.post('/product_main_locations/remove_location', {
        product_main_location_id,
      });

      const { data } = await client_v2.get(
        `/product_main_locations/${product_main_location_id}?context=ui_product_show`
      );
      dispatch({
        type: "EDIT_PRODUCT_UPDATE_MAIN_LOCATION",
        data,
      });
      toastr.success("Sukces", "Lokalizacja została usunięta");
    } catch (error) {
      toastr.error("Błąd", getApiErrorMessage(error));
      throw error;
    }
  };
