import React from "react";
import { connect } from "react-redux";
import Delivery from "./components/Delivery";
import DeliveryToMainWarehouse from "./components/DeliveryToMainWarehouse";
import MilltoDelivery from "./components/MilltoDelivery";
import Shift from "./components/Shift";
import ShiftBatches from "./components/ShiftBatches";
import Simplified from "./components/Simplified";
import checkDeliveryKind from "../const/checkGrnKind";

const GrnKind = ({ data }) => {
  const grn_kind = checkDeliveryKind({
    is_simplified: data.is_simplified,
    kind: data?.kind,
    warehouse_id: data?.warehouse?.id,
    valid_for_grn_line_batches: data?.valid_for_grn_line_batches,
  });

  switch (grn_kind) {
    case "simplified":
      return <Simplified />;
    case "shift":
      return <Shift />;
    case "shift_batches":
      return <ShiftBatches />;
    case "delivery_to_main_warehouse":
      return <DeliveryToMainWarehouse />;
    case "millto_delivery":
      return <MilltoDelivery />;
    case "delivery":
      return <Delivery />;
    default:
      return null;
  }
};

export default connect(({ grn: { data } }) => ({ data }))(GrnKind);
