import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useQuery } from "@tanstack/react-query";
import { getCookie, setCookie } from "utils/cookies";
import { Main } from "layouts";
import { PanelLoader } from "components";
import { ReactComponent as InfoIcon } from "icons/info.svg";
import { client_v2 } from "utils/api";

const enabled_emails = ["sylwiaryba@tradecron.co"];

const TimeRecordValidator = ({ current_user, children }) => {
  const time_record_date = getCookie("time_record_date");

  if (
    current_user.role === "admin" ||
    enabled_emails.includes(current_user.email)
  ) {
    return children;
  }

  const is_today_time_record = time_record_date
    ? moment(time_record_date).isSame(moment(), "day")
    : false;

  const { isLoading: is_loading } = useQuery(
    ["time_record_date"],
    () => client_v2.get("/users/check_for_active_time_record"),
    {
      enabled: !is_today_time_record,
      select: (res) => res.data,
      retry: false,
      onSuccess: async ({ date }) => {
        setCookie("time_record_date", date, moment(date).endOf("day").toDate());
      },
    }
  );

  if (is_today_time_record) {
    return children;
  }

  return (
    <Main>
      {is_loading ? (
        <PanelLoader />
      ) : (
        <div className="dashboard">
          <header className="dashboard__header dashboard__header--edit">
            <div className="icon__container">
              <InfoIcon />
            </div>
            <h2 className="dashboard__title heading">Uwaga!</h2>
          </header>
          <div className="dashboard__form">
            <p>Nie masz zarejestrowanego czasu rozpoczęcia pracy!</p>
            <p>
              Aby kontunuować dodaj czas pracy w aplikacji{" "}
              <a
                className="link"
                href="https://board.tradesk.pl"
                target="_blank"
              >
                <strong>Board</strong>
              </a>
              .
            </p>
          </div>
        </div>
      )}
    </Main>
  );
};

export default connect(({ current_user }) => ({
  current_user,
}))(TimeRecordValidator);
