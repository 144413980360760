import React, { useEffect } from "react";
import { connect } from "react-redux";

import { Main } from "layouts";
import { getUserErrorsStats } from "actions/user_errors_stats";
import { ReactComponent as ProductIcon } from "icons/product.svg";
import Filters from "./components/Filters";
import Charts from "./components/Charts";

const UserErrorsStats = ({ user_errors_stats, getUserErrorsStats }) => {
  useEffect(() => {
    getUserErrorsStats();
  }, [user_errors_stats.filters]);
  return (
    <Main
      is_loading={["invalid", "loading"].includes(user_errors_stats.status)}
      page={{
        name: "Statystyki błedów",
        icon: <ProductIcon />,
        breadcrumbs: [{ name: "Statystyki błędów" }],
      }}
    >
        <Filters />
        <Charts />
    </Main>
  );
};

export default connect(
  ({ user_errors_stats }) => ({
    user_errors_stats,
  }),
  (dispatch) => ({
    getUserErrorsStats: () => dispatch(getUserErrorsStats()),
  })
)(UserErrorsStats);
