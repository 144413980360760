const getLocationDataFromIdentifier = (identifier) => {
  const data = {
    room: "",
    aisle: "",
    rack: "",
    shelf: "",
    position: "",
  };
  if (identifier?.length > 0) {
    const identifier_arr = identifier
      .split("")
      .filter((item) => item !== "-");
    data.room = identifier_arr.splice(1, 2).join("");
    data.aisle = identifier_arr.splice(2, 2).join("");
    data.rack = identifier_arr.splice(3, 2).join("");
    data.shelf = identifier_arr.splice(4, 2).join("");
    data.position = identifier_arr.splice(5, 2).join("");
  }
  return data;
};

export default getLocationDataFromIdentifier;