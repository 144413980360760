import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { Select } from "expano-components";
import {
  filterGrnLines,
  setGrnLinesFilter,
  setGrnLinesSort,
} from "actions/grns";
import { convertDate } from "utils/helpers";

import getHead from "./const/head";

import { ReactComponent as WarehouseFromIcon } from "icons/warehouse_from.svg";
import { ReactComponent as WarehouseToIcon } from "icons/warehouse_to.svg";
import { ReactComponent as ArrowIcon } from "icons/sort-arrow.svg";
import WarningModalMultiPak from "../WarningModalMultiPak";

const Table = ({
  table_top_space = 0,
  data,
  filters,
  sort,
  setGrnLinesFilter,
  setGrnLinesSort,
  filterGrnLines,
  renderItems,
  switch_warehouse = true,
}) => {
  const [is_loaded, setLoaded] = useState(false);
  const [is_open_warning_multi_pak_modal, setOpenWarningMultiPakModal] =
    useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (is_loaded) {
      filterGrnLines();
    }
  }, [filters.sku, filters.warehouse_id]);

  useEffect(() => {
    if (is_loaded) {
      const active_elements = data.grn_lines.filter(({ product }) => {
        const logistic_eans =
          product?.logistic_eans?.map(({ code }) => code) || [];
        const codes = [
          product.sku,
          product?.producer_code,
          product?.ean,
          ...logistic_eans,
        ].filter(Boolean);
        return codes.includes(filters.sku);
      });
      if (active_elements.length === 1) {
        let el = null;
        if (data.kind === "shift") {
          if (!data.is_dispatched) {
            el = document.querySelector(
              `[data-focus-id="${active_elements[0].id}"] .dispatched .save-group input`
            );
          } else {
            el = document.querySelector(
              `[data-focus-id="${active_elements[0].id}"] .income .save-group input`
            );
          }
        } else {
          el = document.querySelector(
            `[data-focus-id="${active_elements[0].id}"] .save-group input`
          );
        }

        if (el) {
          setTimeout(() => {
            el.focus();
          }, 200);
        }
      }
    }
  }, [filters.sku]);

  const head = getHead({
    is_simplified: data.is_simplified,
    kind: data.kind,
    warehouse_id: data?.warehouse?.id,
    valid_for_grn_line_batches: data?.valid_for_grn_line_batches,
    from_name: data?.from_name,
    to_name: data?.to_name,
  });

  const warehouse_options = data.warehouses.map(({ name, id }) => ({
    label: name,
    value: id,
  }));

  const active_warehouse_name = data?.warehouses?.find(
    ({ id }) => id === filters?.warehouse_id
  )?.name;

  return (
    <>
      {is_open_warning_multi_pak_modal && (
        <WarningModalMultiPak
          handleClose={() => setOpenWarningMultiPakModal(false)}
        />
      )}
      <div
        className="grn-progress"
        style={{ width: `${data?.progress || 0}%` }}
      />

      <div
        className={classNames("grn-header", {
          without_warehouse: !switch_warehouse,
        })}
      >
        <div className="grn-header__box">
          <div className="form-group">
            <div className="inp-wrapper">
              <label htmlFor="email" className="label-form">
                Wyszukaj
              </label>
              <div className="inp-group">
                <input
                  type="text"
                  id="sku"
                  name="sku"
                  placeholder="SKU, kod, nazwa, lokalizacja"
                  onChange={({ target: { value } }) => {
                    if (data.logistic_codes?.[value]) {
                      if (data.logistic_codes?.[value] > 1) {
                        setOpenWarningMultiPakModal(true);
                        setGrnLinesFilter("sku", value);
                      }
                    }
                    setGrnLinesFilter("sku", value);
                  }}
                  value={filters?.sku}
                />
              </div>
            </div>
          </div>
        </div>
        {switch_warehouse && (
          <div className="grn-header__box">
            <div className="form-group">
              <div className="inp-wrapper">
                <label className="label-form">Magazyn</label>
                <Select
                  options={warehouse_options}
                  value={warehouse_options.find(
                    ({ value }) => value === filters.warehouse_id
                  )}
                  handleChange={(value) =>
                    setGrnLinesFilter("warehouse_id", value)
                  }
                />
              </div>
            </div>
          </div>
        )}
        <div className="grn-header__box">
          <div className="info-boxes-wrapper">
            <div className="info-box">
              <div className="info-box__icon"></div>
              <div className="info-box__text">
                <p>
                  {convertDate({
                    value: data?.prepared_at,
                    seperator: ".",
                  })}
                </p>
                <p className="subtitle">Data dokumentu</p>
              </div>
            </div>
            <div className="info-box">
              <div className="info-box__icon">
                <WarehouseFromIcon />
              </div>
              <div className="info-box__text">
                <p>{data?.from_name}</p>
                <p className="subtitle">Wydanie</p>
              </div>
            </div>
            <div className="info-box">
              <div className="info-box__icon">
                <WarehouseToIcon />
              </div>
              <div className="info-box__text">
                <p>{data?.to_name}</p>
                <p className="subtitle">Odbiór</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="supply-table"
        style={table_top_space > 0 ? { marginTop: table_top_space } : {}}
      >
        <table>
          <thead
            className="supply-table__head"
            style={{ borderBottom: "1px solid #EBEDF2" }}
          >
            <tr>
              {head.map(({ label, name, sortable = true, style = {} }) =>
                sortable ? (
                  <th style={style} key={name}>
                    <button
                      type="button"
                      onClick={() => {
                        if (sortable) {
                          setGrnLinesSort({
                            order:
                              sort.order && sort.order === "desc"
                                ? "asc"
                                : "desc",
                            column: name,
                          });
                        }
                      }}
                      className={classNames("sort-button", {
                        active: sort.column === name,
                        asc: sort.column === name && sort.order === "asc",
                      })}
                    >
                      <p>
                        <span>{label}</span>
                        {["location", "stock"].includes(name) && (
                          <span className="subinfo">
                            {active_warehouse_name}
                          </span>
                        )}
                      </p>
                      <ArrowIcon />
                    </button>
                  </th>
                ) : (
                  <th style={style} key={name}>
                    <p>
                      <span>{label}</span>
                      {["location", "stock"].includes(name) && (
                        <span className="subinfo">{active_warehouse_name}</span>
                      )}
                    </p>
                  </th>
                )
              )}
            </tr>
          </thead>
          <tbody>{renderItems()}</tbody>
        </table>
      </div>
    </>
  );
};

export default connect(
  ({ grn: { data, filters, sort } }) => ({
    data,
    filters,
    sort,
  }),
  (dispatch) => ({
    setGrnLinesFilter: (name, value) =>
      dispatch(setGrnLinesFilter(name, value)),
    setGrnLinesSort: (data) => dispatch(setGrnLinesSort(data)),
    filterGrnLines: () => dispatch(filterGrnLines()),
  })
)(Table);
