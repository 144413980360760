export const grn_error_options = [
  { label: "Błędny produkt na lokalizacji", value: "wrong_product_on_location" },
  { label: "Źle rozniesiony", value: "wrong_relocated" },
  { label: "Nieprawidłowa ilość", value: "wrong_quantity" },
  { label: "Nieprzygotowano do sprzedaży", value: "wrong_prepare_to_sale" },
];

export const getGrnErrorName = (type) =>
  grn_error_options.find(({ value }) => value === type)?.label;

export default grn_error_options;
